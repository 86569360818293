import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

const IdAutocomplete = (props) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const renderMethod = (event) => {
    return <div>{event.name}</div>;
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        fetch(`${props.url}${value}`)
          .then((response) => response.json())
          .then((data) => {
            setSuggestions(data);
          });
      }}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={(event) => event.id}
      renderSuggestion={renderMethod}
      inputProps={{
        placeholder: props.placeholder || "Search",
        name: "id",
        value,
        onChange: (_e, { newValue, method }) => {
          setValue(`${newValue}`);
        },
        name: props.name,
        id: props.id,
      }}
    />
  );
};
export default IdAutocomplete;
