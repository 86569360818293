import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

const NavigationAutocomplete = () => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        fetch(`/search.json?q=${value}`)
          .then((response) => response.json())
          .then((data) => {
            setSuggestions(data);
          });
      }}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={(event) => event.name}
      renderSuggestion={(result) => {
        const path = result.class == "Event" ? `events` : `people`;
        return (
          <div>
            <a className="suggest_item" href={`/${path}/${result.name}`}>
              {result.name}
            </a>
          </div>
        );
      }}
      inputProps={{
        placeholder: "Search",
        name: "q",
        value,
        onChange: (_e, { newValue, method }) => {
          if (method != "click") {
            setValue(`${newValue}`);
          }
        },
      }}
    />
  );
};
export default NavigationAutocomplete;
