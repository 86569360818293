import React, { useState } from "react";
import { createRoot } from "react-dom/client";

import NavigationAutocomplete from "../NavigationAutocomplete";
import PersonNavigationAutocomplete from "../PersonNavigationAutocomplete";
import IdAutocomplete from "../IdAutocomplete";

const domContainer = document.querySelector("#top_search_container");
const root = createRoot(domContainer);
root.render(React.createElement(NavigationAutocomplete));

const domContainer_p = document.querySelector("#person_id_auto_container");
if (domContainer_p) {
  const person_root = createRoot(domContainer_p);
  person_root.render(React.createElement(PersonNavigationAutocomplete));
}

const domContainer2 = document.querySelector("#event_link_event2_id_container");
if (domContainer2) {
  const inputName = domContainer2.attributes.inputname.value;
  const root2 = createRoot(domContainer2);
  root2.render(
    React.createElement(IdAutocomplete, {
      url: "/events/search.json?q=",
      name: inputName,
      id: "event_link_event2_id",
      placeholder: "Search events",
    })
  );
}

const domContainer3 = document.querySelector(
  "#person_link_person_id_container"
);
if (domContainer3) {
  const inputName = domContainer3.attributes.inputname.value;
  const root2 = createRoot(domContainer3);
  root2.render(
    React.createElement(IdAutocomplete, {
      url: "/people/search.json?q=",
      name: inputName,
      id: "event_link_event2_id",
      placeholder: "Search people",
    })
  );
}
